
@font-face {
    font-family: "Borna Medium";
    src: url("../fonts/borna-medium-webfont.woff2") format("woff2");
}
@font-face {
    font-family: "Borna Regular";
    src: url("../fonts/borna-regular-webfont.woff2") format("woff2");
}
@font-face {
    font-family: "Borna SemiBold";
    src: url("../fonts/borna-semibold-webfont.woff2") format("woff2");
}
@font-face {
    font-family: "Borna Bold";
    src: url("../fonts/borna-bold-webfont.woff2") format("woff2");
}