.borna-medium,
.borna-regular,
.borna-semibold,
.borna-bold
p {
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    line-height: 1.2;
}
p {
    font-family: 'Fira Mono', monospace;
    font-size: 0.6em;
}
a, small {
    //font-family: "Borna Regular", sans-serif;
   // font-size: 0.7em;
    text-transform: uppercase;
}
a {
    color: black;
    display: inline-block;
    text-decoration: none;
    @include on-event {
        text-decoration: none;
    }
}
.borna-medium {
    font-family: "Borna Medium", sans-serif;
}
.borna-regular {
    font-family: "Borna Regular", sans-serif;
}
.borna-semibold {
    font-family: "Borna SemiBold", sans-serif;
}
.borna-bold {
    font-family: "Borna Bold", sans-serif;
}


header {
    padding: 2em;
    p {
        margin: 1.678em 0;
    }
}
section {
    padding: 2em 2em;
    h1 {
        font-family: 'Fira Mono', monospace;
        color: $brand-secondary-color;
        text-transform: uppercase;
        font-size: 9em;
        margin: 0.2em;
        word-wrap: break-word;
    }
}
